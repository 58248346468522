<template>
  <div class="agreement">
    <!--    <div class="header"><img @click="back" class="back" src="https://cdn.cailu88.com/jingxuanshi/back_arrow.png" alt="">用户注册服务协议</div>-->
    <div class="content">
      <span class="important">特别提示:</span>
      <br/>
      下述条款为格式条款，任何用户（注册、登录或使用鲸选师App的个人为本协议所述用户，下同）或拟注册用户均须逐字阅读和理解下述协议的条款。在使用“鲸选师”软件之前，请您认真阅读并同意该协议，及
      《鲸选师隐私权政策》，这是我们向您提供许可软件及许可软件相关服务的前提。我们特别在此提示您注意阅读加粗内容。如果对下述条款存在任何疑问，由鲸选师工作人员予以解释，在鲸选师工作人员回复之前，且取得清晰且完全的解释并使用户或拟注册用户排除疑问之前，请暂停注册或使用本App。
      <br/>
      <br/>
      接受和遵守下述条款是注册为“鲸选师”用户和使用“鲸选师”服务的条件和前提。注册为“鲸选师”用户和使用“鲸选师”服务即视为接受对下述条款的接受。不接受或不完全接受下述条款，您应立即停止注册为“鲸选师”用户和使用“鲸选师”服务。
      <br/>
      <br/>
      本 app 仅是为用户提供电子商务平台服务的媒介，不因本平台服务与用户发生任何直接商品交易，不是用户之间或用户与第三人之间发生的商品买卖或其它法律关系的主体。
      <br/>
      <br/>
      鲸选师及其提供的网上交易平台仅作为淘宝天猫商家或其他合作商家提供产品及产品信息的展示平台。对于鲸选师网上交易平台上的产品和信息，鲸选师仅具有和包括鲸选师用户在内的普通消费者相同程度的信息获取能力，不具备保证淘宝天猫商家或其他合作商家相关产品信息的真实性、准确性、合法性和有效性，以及交易双方履行其在买卖协议项下的各项义务的能力。
      <br/>
      <br/>
      请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。本协议的内容包括协议正文及所有我们已经公布的
      适用于所有使用者的各类服务条款及规则(诸如平台规则等)，未来我们会继续丰富相关的服务条款与规则，并依法定程序公布生效。所有条款与规则为协议不可分割的组成部分，与协议具有同等法律效力。
      <br/>
      <br/>
      <span class="important">一、概述:</span>
      <br/>
      1.本协议约束鲸选师与用户之间关于“鲸选师App”软件服务（简称“服务”）的权利义务。本用户协议所述“鲸选师”指杭州聚心选网络科技有限公司，“用户”指注册、登录或使用鲸选师App的个人。
      <br/>
      2.本协议内容包括协议正文及鲸选师根据本协议所制定和修订的相关管理规范。
      <br/>
      <br/>
      <span class="important">二、账号注册:</span>
      <br/>
      1.用户在使用本服务前需要注册一个“鲸选师”账号。“鲸选师”账号应当使用手机号码绑定注册，请用户使用尚未与“鲸选师”账号绑定的手机号码，以及未被鲸选师根据本协议封禁的手机号码注册“鲸选师”账号。
      <br/>
      2.“鲸选师”系基于电子商务交易平台的APP产品，用户注册时应当授权鲸选师公开及使用其个人信息方可成功注册“鲸选师”账号。故用户完成注册即表明用户同意鲸选师提取、公开及使用用户的信息。
      <br/>
      3.鉴于“鲸选师”账号的绑定注册方式，您同意鲸选师在注册时将允许您的手机号码及手机设备识别码等信息用于注册。
      <br/>
      4.在用户注册及使用本服务时，鲸选师需要搜集能识别用户身份的个人信息以便鲸选师可以在必要时联系用户，或为用户提供更好的使用体验。鲸选师同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
      <br/>
      5、您一经注册账号，即视为您同意鲸选师及/或关联公司通过短信或者电子邮件的方式向您
      注册时填写的手机号码或者电子邮箱发送相应的商品广告信息、促销优惠等营销信息；如果
      您不同意发送，可通过相应的退订功能进行退订。
      <br/>
      6、鲸选师和用户均有权随时解除或终止本协议，互相无须因解除或终止本协议承担任何责
      任，具体可联系鲸选师工作人员了解详情。
      <br/>
      7、由于用户账号关联用户信息，仅当前法律铭文规定、司法裁定或鲸选师同意，并符合鲸
      选师平台规则规定的用户账号转让流程的情况下，您可进行账户的转让。用户账户一经转让，
      该账户项下权利义务一并转移。除此之外，您的账户不得以任何方式转让，否则鲸选师平台
      有权追究您的违约责任，且由此产生的责任和后果均由您自行承担。
      <br/>
      <br/>
      <span class="important">三、用户个人隐私信息保护:</span>
      <br/>
      1、如果鲸选师发现或收到他人举报或投诉用户违反本协议约定的，鲸选师有权按照国家法
      律法规要求对相关内容，包括但不限于用户资料、发贴记录进行审查、删除，并视情节轻重
      对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁的处罚，且通知用户
      处理结果。
      <br/>
      2、因违反用户协议被封禁的用户，可以自行与鲸选师联系。其中，被实施功能封禁的用户
      会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，鲸选师将对申诉进行审查，
      并自行合理判断决定是否变更处罚措施。
      <br/>
      3、用户理解并同意，鲸选师有权依合理判断对违反有关法律法规或本协议规定的行为进行
      处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部
      门报告等，用户应承担由此而产生的一切法律责任。
      <br/>
      4、用户理解并同意，因用户违反本协议约定或发生其它过错，导致或产生的任何第三方向
      鲸选师或其合作公司、关联公司主张任何索赔、要求，如果造成鲸选师或其合作公司、关联
      公司发生的任何损失，包括合理的律师费，用户应当赔偿鲸选师与合作公司、关联公司，并
      使之免受损害。
      <br/>
      <br/>
      <span class="important">四、用户发布内容规则:</span>
      <br/>
      1、本条所述内容是指用户使用鲸选师的过程中所制作、上载、复制、发布、传播的任何内
      容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、
      视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所
      产生的内容。
      <br/>
      2、用户不得利用“鲸选师”账号或本服务制作、上载、复制、发布、传播如下法律、法规
      和政策禁止的内容：
      <br/>
      (1)反对宪法所确定的基本原则的；
      <br/>
      (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      <br/>
      (3)损害国家荣誉和利益的；
      <br/>
      (4)煽动民族仇恨、民族歧视，破坏民族团结的；
      <br/>
      (5)破坏国家宗教政策，宣扬邪教和封建迷信的；
      <br/>
      (6)散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br/>
      (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      <br/>
      (8)侮辱或者诽谤他人，侵害他人合法权益的；
      <br/>
      (9)含有法律、行政法规禁止的其他内容的信息。
      <br/>
      3、用户不得利用“鲸选师”账号或本服务制作、上载、复制、发布、传播如下干扰“鲸选
      师”正常运营，以及侵犯其他用户或第三方合法权益的内容：
      <br/>
      (1)含有任何性或性暗示的；
      <br/>
      (2)含有辱骂、恐吓、威胁内容的；
      <br/>
      (3)含有骚扰、垃圾广告、恶意信息、诱骗信息的；
      <br/>
      (4)涉及他人隐私、个人信息或资料的；
      <br/>
      (5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
      <br/>
      (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
      <br/>
      <br/>
      <span class="important">五、使用规则:</span>
      <br/>
      1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为
      反映或代表鲸选师的观点、立场或政策，鲸选师对此不承担任何责任。
      <br/>
      2、用户不得利用“鲸选师”账号或本服务进行如下行为：
      <br/>
      (1)提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；
      <br/>
      (2)虚构事实、隐瞒真相以误导、欺骗他人的；
      <br/>
      (3)利用技术手段批量建立虚假账号的；
      <br/>
      (4)利用“鲸选师”账号或本服务从事任何违法犯罪活动的；
      <br/>
      (5)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论
      <br/>
      这些行为是否为商业目的；
      <br/>
      (6)其他违反法律法规规定、侵犯其他用户合法权益、干扰“鲸选师”正常运营或鲸选师未
      <br/>
      明示授权的行为。
      <br/>
      3、用户须对利用“鲸选师”账号或本服务传送信息的真实性、合法性、无害性、准确性、
      有效性等全权负责。如因此给鲸选师或第三方造成损害的，用户应当依法予以赔偿。
      <br/>
      4、鲸选师提供的服务中可能包括广告，用户同意在使用过程中显示鲸选师和第三方供应商、
      合作伙伴提供的广告。
      <br/>
      <br/>
      <span class="important">六、鲸选师平台说明:</span>
      <br/>
      1、鲸选师作为电子商务交易平台，遵循以下原则：
      <br/>
      (1)从不创造、编辑、修改鲸选师买家登录的内容；
      <br/>
      (2)买家在鲸选师电商平台商品成交后，鲸选师不负责运送、储存或递交，商品直接从淘宝
      天猫商家或其他合作商家转移到鲸选师买家手中，商品本身也从不为鲸选师所持有或处于鲸
      选师的控制范围内；
      <br/>
      (3)鲸选师电商平台从不允许买卖双方进行线下交易，对于违反规定的交易行为不承担任何
      责任；
      <br/>
      (4)鲸选师电商平台不对第三方知识产权权利人的产品，商标权，著作权，专利权，经销权
      和价格体系具有专业知识；
      <br/>
      2、鲸选师及其提供的网上交易平台仅作为淘宝天猫商家或其他合作商家提供产品及产品信
      息的展示平台。
      <br/>
      3、对于鲸选师网上交易平台上的产品和信息，鲸选师不具备保证淘宝天猫商家或其他合作
      商家相关产品信息的真实性、准确性、合法性、时效性和有效性，以及交易双方履行其在买
      卖协议项下的各项义务的能力，但鲸选师网上交易平台另行公示商品售后服务详情的，则以
      公示为准。
      <br/>
      4、如用户在购物过程中有任何疑问或与商家产生纠纷的，用户可以通过鲸选师官方渠道进
      行咨询和投诉，鲸选师将依据有关法律法规之规定对商品和服务质量进行监督，维护用户权
      益。
      <br/>
      5、因淘宝天猫商家与参与交易的鲸选师买家之间的交易行为引起的任何法律纠纷，包括但
      不限于投诉、起诉、举报及税赋等，均由参与交易的双方解决，鲸选师无义务参与或解决鲸
      选师买家与淘宝天猫商家的争议。但如果淘宝天猫商家怠于履行义务时，鲸选师有权介入淘
      宝天猫商家与鲸选师买家间的争议，依据一般人的认知程度对该争议进行判断和处置。
      <br/>
      <br/>
      <span class="important">七、协议变更:</span>
      <br/>
      鲸选师可根据国家法律法规变化及保护消费者权益需要，不时修改本协议，变更后的协议将
      通过法定程序提前7日并以鲸选师App通知等形式告知您，如您不同意变更事项，您有权
      于变更事项确定的生效日前联系鲸选师反馈意⻅。如反馈意⻅得以采纳，鲸选师将酌情调
      整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效日起停
      止使用鲸选师平台服务，变更事项对您不产生效力;如您在变更事项生效后仍继续使用鲸选
      师平台服务，则视为您同意已生效的变更事项。
      <br/>
      <br/>
      <span class="important">八、法律适用、管辖与其他:</span>
      <br/>
      1、鲸选师郑重提醒用户注意本协议中免除鲸选师责任和限制用户权利的条款，请用户仔细
      阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
      <br/>
      2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和鲸选师之间
      发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交鲸选
      师住所地有管辖权的人民法院管辖。
      <br/>
      3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有
      约束力。
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  mounted () {
    window.scrollTo({
      top: 0
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.agreement {
  padding-bottom: 34px;
}

.agreement .header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: sticky;
  font-size: 18px;
  top: 0;
  left: 0px;
  background: white;
  width: 375px;
  position: relative;
}

.agreement .header .back {
  width: 9px;
  height: 16px;
  position: absolute;
  left: 14px;
  bottom: 14px;
}

.agreement .content {
  margin-top: 18px;
  padding: 0 18px;
  font-size: 15px;
  color: #333333;
  line-height: 21px;
  color: #333333;
}

.agreement .content .important {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin-bottom: 12px;

}
</style>
